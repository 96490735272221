import PaymentMethodSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.2.2/payment-method-selector-100/index.vue';
import creditNotRefund from '@/lib/data-service/default/web_credit_creditNotRefund'

export default {
    data() {
        return {
            detail: {},
            loading: false,
            amount: 0,
            radio: '',
            orderList: '',
            formData: {
                refundType: 1,
                orderAmount: 0,
                billIds: ''
            }
        }
    },
    components: {
        PaymentMethodSelector,
    },
    methods: {
        newPaySubmit() {
            const __this = this;
            __this.formData = Object.assign(__this.formData, {
                companyId: __this.detail.results[0].companyId
            })
            console.log(this.formData)
            __this.$refs.aPaymentMethodSelector.init({
                get_params() {
                    return __this.formData;
                },

                count_down: {
                    enable: false,
                },

                before_pay_event_handler() {
                },

                //支付成功事件处理方法
                pay_successful_event_handler() {
                    __this.$router.push({name: 'distributor-easy-pay-easy-pay-account'})
                },

                //支付失败事件处理方法
                pay_failed_event_handler() {
                },
            });
        },
        selectCheck() {
            this.formData.orderAmount = this.detail.results.reduce((num, item) => {
                if (item.statuOrder) {
                    num += item.billAmount
                }
                return num;
            }, 0)
        },
        getDetail() {
            let billIds = this.$route.query.ids
            this.loading = true
            creditNotRefund({billIds}).then(res => {
                res.results.map(item => {
                    item.billStatus == 3 ? item.statuOrder = true : item.statuOrder = false
                })
                this.$nextTick(() => {
                    this.detail = res
                    this.loading = false
                    this.formData.orderAmount = res.totalAmount
                    this.newPaySubmit()
                })
            })
        }
    },
    activated() {
        this.formData.refundType = this.$route.query.type
        this.formData.billIds = this.$route.query.ids
        this.getDetail()
    }
}
